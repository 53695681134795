
import { defineComponent } from 'vue'
import NavMenu from '@/components/molecules/NavMenu.vue'
import Header from '@/components/sections/Header.vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import authHeader from '@/services/auth-header'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'Admin',
  data () {
    return {
      file: null,
      isModalOpen: false,
      importSuccessful: false,
      error: null,
      fileType: ''
    }
  },
  components: {
    Header,
    DefaultPopup,
    NavMenu,
    AlertBlock
  },
  methods: {
    clearCache () {
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('file', this.file)
      }
      formData.append('type', this.fileType)
      this.axios.post(API_URL + 'admin/products/clear-cache', {}, { headers: authHeader() }).then(() => {
        console.log('clear cache')
      },
      (error) => {
        if (error.response.status === 401) {
          this.$router.push('/')
          localStorage.removeItem('user')
        }
        this.error = error.response.data.errors
      }
      )
    },
    importFile () {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('file', this.file)
      }
      formData.append('type', this.fileType)
      this.axios.post(API_URL + 'admin/products/import', formData, { headers: authHeader() }).then(() => {
        this.importSuccessful = true
      },
      (error) => {
        if (error.response.status === 401) {
          this.$router.push('/')
          localStorage.removeItem('user')
        }
        this.error = error.response.data.errors
      }
      )
    },
    handleFileUpload () {
      this.error = null
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = true
          this.isPreviewVideo = false
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
        console.log(this.file.type, 'this.file.type')
        if (this.file.type === 'video/mp4' || this.file.type === 'video/quicktime') {
          this.isPreview = false
          this.isPreviewVideo = true
        }
      }
    },
    openModal (type) {
      this.isModalOpen = true
      this.fileType = type
    },
    closeModal () {
      this.fileType = ''
      this.isModalOpen = false
      this.importSuccessful = false
      this.file = null
      this.error = null
    }
  }
})


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavMenu',
  data () {
    return {}
  },
  components: {
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(
        (res) => {
          this.$router.push('/')
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})


import { defineComponent } from 'vue'
import Admin from '@/components/sections/Admin.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Admin
  }
})

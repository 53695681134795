import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e10c73f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-menu__container" }
const _hoisted_2 = { class: "nav-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "nav-menu__link",
        to: "/admin"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Прайси")
        ]),
        _: 1
      })
    ])
  ]))
}